import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="lightbox"
export default class extends Controller {
  static targets = ["thumbnail", "lightbox", "lightboxImage"];

  open() {
    this.lightboxImageTarget.src = this.thumbnailTarget.src;
    this.lightboxImageTarget.style.transform = ` scale(0.1)`;
    this.lightboxTarget.classList.remove("hidden");

    requestAnimationFrame(() => {
      this.lightboxTarget.classList.add("bg-black/80");
      this.lightboxImageTarget.classList.add("opacity-100");
      this.lightboxImageTarget.style.transform = `scale(1)`;
    });
  }

  close() {
    this.lightboxTarget.classList.add("hidden");
  }
}
