import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["avatar"];

  connect() {
    this.headerRef = this.element;
    this.avatarRef = this.avatarTarget;

    this.downDelay = this.avatarRef.offsetTop || 0;
    this.upDelay = 50;

    this.updateStyles = this.updateStyles.bind(this);
    window.addEventListener("scroll", this.updateStyles, { passive: true });
    window.addEventListener("resize", this.updateStyles);
    this.updateStyles();
  }

  updateStyles() {
    this.updateHeaderStyles();
    this.updateAvatarStyles();
  }

  updateHeaderStyles() {
    const { top, height } = this.headerRef.getBoundingClientRect();
    const scrollY = Math.max(
      0,
      Math.min(window.scrollY, document.body.scrollHeight - window.innerHeight),
    );

    document.documentElement.style.setProperty(
      "--content-offset",
      `${this.downDelay}px`,
    );

    if (scrollY < this.downDelay) {
      document.documentElement.style.setProperty(
        "--header-height",
        `${this.downDelay + height}px`,
      );
      document.documentElement.style.setProperty(
        "--header-mb",
        `${-this.downDelay}px`,
      );
    } else if (top + height < -this.upDelay) {
      const offset = Math.max(height, scrollY - this.upDelay);
      document.documentElement.style.setProperty(
        "--header-height",
        `${offset}px`,
      );
      document.documentElement.style.setProperty(
        "--header-mb",
        `${height - offset}px`,
      );
    } else if (top === 0) {
      document.documentElement.style.setProperty(
        "--header-height",
        `${scrollY + height}px`,
      );
      document.documentElement.style.setProperty(
        "--header-mb",
        `${-scrollY}px`,
      );
    }

    if (top === 0 && scrollY > 0 && scrollY >= this.downDelay) {
      document.documentElement.style.setProperty(
        "--header-inner-position",
        "fixed",
      );
      document.documentElement.style.removeProperty("--header-top");
      document.documentElement.style.removeProperty("--avatar-top");
    } else {
      document.documentElement.style.removeProperty("--header-inner-position");
      document.documentElement.style.setProperty("--header-top", "0px");
      document.documentElement.style.setProperty("--avatar-top", "0px");
    }
  }

  updateAvatarStyles() {
    const fromScale = 1;
    const toScale = 0.75;
    const fromX = 0;
    const toX = 2 / 16;

    const scrollY = this.downDelay - window.scrollY;
    const scale = Math.max(
      toScale,
      Math.min(
        fromScale,
        (scrollY * (fromScale - toScale)) / this.downDelay + toScale,
      ),
    );
    const x = Math.max(
      toX,
      Math.min(fromX, (scrollY * (fromX - toX)) / this.downDelay + toX),
    );

    document.documentElement.style.setProperty(
      "--avatar-image-transform",
      `translate3d(${x}rem, 0, 0) scale(${scale})`,
    );
  }
}
