import { Application } from "@hotwired/stimulus";
import Notification from "@stimulus-components/notification";
import Dropdown from "@stimulus-components/dropdown";
import Clipboard from "@stimulus-components/clipboard";
import CharacterCounter from "@stimulus-components/character-counter";
import TextareaAutogrow from "stimulus-textarea-autogrow";

const application = Application.start();

application.register("notification", Notification);
application.register("dropdown", Dropdown);
application.register("clipboard", Clipboard);
application.register("character-counter", CharacterCounter);
application.register("textarea-autogrow", TextareaAutogrow);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };
